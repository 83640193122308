"use strict";
import ClientDatatable from "./ClientDatatable";
import ProjectDatatable from "./ProjectDatatable";
import TemplateDatatable from "./TemplateDatatable";
import LibraryDatatable from "./LibraryDatatable";
import DoorlistTemplateDatatable from "./DoorlistTemplateDatatable";

var KTDatatablesSearchOptionsColumnSearch = function() {


    return {

        //main function to initiate the module
        init: function() {
            console.log("THIS IS INIT OF COLUMN SEARCH WILL CALL INITTABLE 1")

            $.fn.dataTable.Api.register('column().title()', function() {
                return $(this.header()).text().trim();
            });

            const model = $('#context').attr('data-model');
            if(model == 'client'){
                ClientDatatable();
            } else if(model == 'project'){
                ProjectDatatable();
            } else if(model == 'library'){
                LibraryDatatable();
            } else if(model == 'template'){
                TemplateDatatable();
            } else if(model == 'doorlist_template'){
                DoorlistTemplateDatatable();
            }
        },

    };

    let dtblediv = $("#kt_datatable")
    if(dtblediv){
        $('#kt_datatable').css('width', '100%')
    }

}();

function columnsDefinitions(model){
    let defs = '';
    if(model == 'position'){
        defs = [
            { data: 'Name' },
            { data: 'Description' },
            { data: 'Dimensions' },
            { data: 'Kosten Pro Stuck'}

        ]
    } else if (model == 'client'){
        defs = [ {data: 'Name'}, {data: 'City'}, {data: 'Phone'}, {data: 'Actions', responsivePriority: -1}]
    } else if (model == 'project') {
        defs = [{data: 'ID'}, {data: 'Name'}, {data: 'Client'}, { data: 'Options' }]
    } else if (model == 'calculation_template') {
        defs = [{data: 'ID'}, {data: 'Name'}, {data: 'Category'}, {data: 'Content'}, { data: 'Options'}]
    }
    console.log("returning defs for columnsDefinitions: " + defs)
    return defs;
}

function resolveFields(model){
    let fields = [];
    if (model == 'position'){
        fields = [ 'Name', 'Description', 'Dimensions', 'Kosten Pro Stuck', 'Stuckmarge', 'Offerte P. Stuck',, 'Stuckzahl' , 'Options']
    } else if (model == 'element' ) {
        fields = ['ID', 'Name', 'Category', 'Price', 'Actions']
    } else if (model == 'client'){
        fields = [ 'Name', 'City', 'Phone', 'Actions']
    } else if (model == 'project'){
        fields = ['ID', 'Name', 'Client', 'Options']
    }
    return fields;
}

$(document).on('turbolinks:load', function() {
    console.log('we are in KTDAtatablesomething...')
    KTDatatablesSearchOptionsColumnSearch.init();
});

jQuery(document).ready(function() {

});
