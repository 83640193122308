"use_strict";

export default function calculateOffer() {
    console.log('CALCULATE OFFER :: start ...');
    let positionsTotal = 0;
    $('.offer-item-row').each(function(){
        let $this = $(this)
        let checkBox = $(this).find('.calculation-checkbox')
        let profit = checkBox.attr('data-profit');
        console.log('position profit is => ' + profit);
        if(checkBox.is(':checked')){
            let price = parseFloat($this.find('.offered-price').val());
            console.log("price is " + price)
            let costPerPiece = parseFloat(checkBox.attr('data-cost-per-piece'));
            console.log('cost per piece is ' + costPerPiece);
            let pieceProfit = price - costPerPiece;
            console.log('piece profit is ' + pieceProfit);
            let quantity = parseFloat($this.find('.offered-quantity').val());
            let rowTotal = price * quantity;
            let calculationType = $(this).attr('data-calculation-type')
            console.log('calculation type is ' + calculationType)
            if(calculationType == 'doorlist'){
                let rowProfit = parseFloat(checkBox.attr('data-profit'));
                console.log('rowProfit is ' + rowProfit)
                $this.find('.row-total').text(rowTotal.toFixed(2));
                $this.find('.row-profit').text(rowProfit.toFixed(2))
            } else {
                let rowProfit = pieceProfit * quantity;
                $this.find('.row-total').text(rowTotal.toFixed(2));
                $this.find('.row-profit').text(rowProfit.toFixed(2))
            }

        }
    })
    recalculate();
}

function recalculate(){
    let totalCalc = getCalculationsTotal();
    let profitTotal = totalCalc[1];
    console.log("CALCULATE OFFER :: recalculate :: totalCalc is =>  " + totalCalc);
    let positionsTotal = totalCalc[0];
    console.log("CALCULATE OFFER :: recalculate :: positionsTotal  IN recalculate " + positionsTotal);
    $('#offer-offer-total').text(positionsTotal);
    let beforeDiscounts = beforeDiscountsValue(positionsTotal)
    console.log("CALCULATE OFFER :: recalculate :: beforeDiscounts  IN recalculate " + beforeDiscounts);
    let totalGross = positionsTotal - beforeDiscounts;
    console.log("CALCULATE OFFER :: recalculate :: totalGross  IN recalculate " + totalGross);
    $('#offer-total-gross').text(totalGross.toFixed(2));
    let mwst = (totalGross / 100) * 7.7;
    $('#offer-mwst').text(mwst.toFixed(2));
    let totalNet = totalGross + mwst
    $('#offer-total-net').text(totalNet.toFixed(2))
    let afterDiscounts = afterDiscountsValue(totalNet)
    let totalNetIncludingDiscount = totalNet - afterDiscounts
    $('#offer-total-net-including-discount').text(totalNetIncludingDiscount.toFixed(2))
    let projectManagerHoursCost = getProjectManagerHoursCost()
    $('#offer-profit-total').text((profitTotal - beforeDiscounts - afterDiscounts - projectManagerHoursCost).toFixed(2));
}

function getProjectManagerHoursCost(){
    let projectManagerHoursCost = 0;
    let includeProjectManagerHoursCost = $('#include-project-manager-hours').is(':checked')
    if(includeProjectManagerHoursCost){
        projectManagerHoursCost = $('#project-manager-hours-cost').val()
    }
    return projectManagerHoursCost;
}

function beforeDiscountsValue(positionsTotal){
    let totalBeforeDiscountsValue = 0;
    $('.offer-discount-field').each(function(){
        let currentDiscountValue = 0
        let type = $(this).attr('data-position')
        let $this = $(this);
        console.log('CALCULATE OFFER :: beforeDiscountsValue :: loop type => ' + type)
        if(type == 'before'){
            let discountType = $(this).attr('data-discount-type');
            console.log('CALCULATE OFFER :: beforeDiscountsValue :: loop type is before and discount type is  => ' + discountType)
            if(discountType == 'CHF'){
                currentDiscountValue = parseFloat($(this).val())
            } else if (discountType == 'percent'){
                let percentage = parseFloat($(this).val());
                currentDiscountValue = (( positionsTotal / 100 ) * percentage)
            }
            let parentDiv = $this.closest('.offer-calculation-totals-row');
            parentDiv.find('.offer-total-row-value').text(-currentDiscountValue.toFixed(2))
        }
        totalBeforeDiscountsValue += currentDiscountValue;
    })
    return totalBeforeDiscountsValue;
}

function afterDiscountsValue(totalNet){
    let totalAfterDiscountsValue = 0;
    $('.offer-discount-field').each(function(){
        let $this = $(this);
        let currentDiscountValue = 0
        let type = $(this).attr('data-position')
        console.log('CALCULATE OFFER :: beforeDiscountsValue :: loop type => ' + type)
        if(type == 'after'){
            let discountType = $(this).attr('data-discount-type');
            console.log('CALCULATE OFFER :: afterDiscountsValue :: loop type is after and discount type is  => ' + discountType)
            if(discountType == 'CHF'){
                currentDiscountValue = parseFloat($(this).val())
            } else if (discountType == 'percent'){
                let percentage = parseFloat($(this).val());
                currentDiscountValue = (( totalNet / 100 ) * percentage)
            }
            let parentDiv = $this.closest('.offer-calculation-totals-row');
            parentDiv.find('.offer-total-row-value').text(-currentDiscountValue.toFixed(2))
        }
        totalAfterDiscountsValue += currentDiscountValue;
    })

    return totalAfterDiscountsValue;
}

function finalDiscountPercentage(){
    let percentage = 0
    let finalDiscountInput = $('#discount').val();
    if(finalDiscountInput > 0){
        percentage = finalDiscountInput
    }
    return percentage;
}

function commercialDiscountPercentage(){
    let percentage = 0
    let commercialDiscountInput = $('#commercial-discount').val();
    if(commercialDiscountInput > 0){
        percentage = commercialDiscountInput
    }
    return percentage;
}

function constructionDiscount1Percentage(){
    let percentage = 0
    let constructionDiscount1Input = $('#construction-discount1').val();
    if(constructionDiscount1Input > 0){
        percentage = constructionDiscount1Input
    }
    return percentage;
}

function firstRabatPercentage() {
    let firstRabat = 0
    let firstRabatInput = $('#first-rabat').val();
    if(firstRabatInput > 0){
        firstRabat = firstRabatInput
    }
    return firstRabat;
}

function getCalculationsTotal(){
    let calculationsTotal = 0;
    let totalProfit = 0
    $('.offer-item-row').each(function() {
        let $this = $(this)
        let checkBox = $(this).find('.calculation-checkbox')
        if (checkBox.is(':checked')) {
            let profit = parseFloat(checkBox.attr('data-profit'));

            let price = parseFloat($this.find('.offered-price').val());
            let quantity = parseFloat($this.find('.offered-quantity').val());
            let rowTotal = price * quantity;
            $this.find('.row-total').text(rowTotal.toFixed(2));
            calculationsTotal += rowTotal;
            totalProfit += (profit * quantity);
        }
    })
    return [calculationsTotal, totalProfit];
}