import React from "react"
import PropTypes from "prop-types"
import styled from 'styled-components';
import OptionSelect from "./OptionSelect";
class DoorRow extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            1: {}, 2: {}, 3: {}, 4: {}, 5: {}, 6: {}, 7: {}, 8: {}, 9: {},
            10: {}, 11: {}, 12: {}, 13: {}, 14: {}, 15: {}, 16: {}, 17: {}, 18: {}, 19: {},
            20: {}, 21: {}, 22: {}, 23: {}, 24: {}, 25: {}, 26: {}, 29: {},
            30: {}, 31: {}, 32: {}, 33: {}, 34: {}, 35: {}, 36: {}, 37: {}, 38: {}, 39: {},
            40: {}, 41: {}, 42: {}, 43: {}, 44: {}, 45: {}, view: '', projectObjectId: null, calculationId: null, visibleIds:[]
        }
    }

    componentDidMount() {
        console.log("DOOR ROW :: componentDidMount :: this.props.positionElements => " + JSON.stringify(this.props.positionElements))

        this.setState(this.props.positionElements)
    }

    _processedSelectedValue = (key) => {
        //console.log("DOOR ROW :: _processedSelectedValue :: state for the key " + key + " is => " + JSON.stringify(this.state[key]))
        let selected = {};
        if(this.state[key]['element_id'] ==  46) {
            //console.log("DOOR ROW :: _processSelectedValue :: state['projectObjectId'] is: " + this.state['projectObjectId'])
            if (this.state['projectObjectId'] == null) {
                selected = {'value': null, 'label': null}
            } else {
                let chosenLabel = '';
                this.state[key]['options'].map((element, index) => {
                    if (element['value'] == this.state['projectObjectId'].toString()) {
                        chosenLabel = element['label']
                    }
                })
                selected = {'value': this.state['projectObjectId'].toString(), 'label': chosenLabel}
            }
        } else if (this.state[key]['element_id'] == 45){
            console.log("DOOR ROW :: _processSelectedValue :: state['calculationId'] is: " + this.state['calculationId'])
            if (this.state['calculationId'] == null) {
                selected = {'value': null, 'label': null}
            } else {
                let chosenLabel = '';
                this.state[key]['options'].map((element, index) => {
                    if (element['value'] == this.state['calculationId'].toString()) {
                        chosenLabel = element['label']
                    }
                })
                selected = {'value': this.state['positionId'].toString(), 'label': chosenLabel}
           }
            selected = this.state[key]['selected_value']
        } else {
            selected = this.state[key]['selected_value']
        }
        //console.log("DOOR ROW :: _processSelectedValue :: selected => " + selected)
        return selected
    }

    _isVisible = (key) => {
        //console.log('DOOR ROW :: _isVisible :: key => ' + key)
        let visibleIds = []
        if(this.props.view == 'compact'){
            visibleIds = [1,2,3,4,5,6,9,10, 11, 12,13, 14, 15, 17, 18, 22, 23,24, 25, 28, 29, 30, 31, 32, 33, 39, 40, 41, 42, 43, 44, 45, 46 ]
        } else {
            visibleIds = [1,2,3,4,5,6, 7, 8, 9,10, 11, 12,13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23,24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35,36,37, 38, 39, 40, 41, 42, 43, 44, 45, 46 ]
        }

        let shouldBeVisible = false;
        if(visibleIds.includes(parseInt(key))){
            shouldBeVisible = true;
        }
        //console.log("DOOR ROW :: _isVisible :: shouldBeVisible is => " + shouldBeVisible)
        return shouldBeVisible;
    }

    _optionsSelect = () => {
        return (
            Object.keys(this.state).map(key => {
                console.log("key in _optionsSelect => " + key)
                console.log("hash for the key => " + JSON.stringify(this.state[key]))
                //console.log("position_element_id for the key => " + this.state[key]['position_element_id'])
                if(key == 45 || key == 4){
                    //console.log("DOOR ROW :: _optionsSelect :: key => " + key + ' and keys length is: ' + key.length);
                    //console.log("DOOR ROW :: _optionsSelect :: options " + JSON.stringify(this.state[key]))
                    //console.log("DOOR ROW :: _optionsSelect :: this.state[key].options " + JSON.stringify(this.state[key]['options']));
                }
                let isVisibleInView = this._isVisible(key);
                if(key.length < 3 && isVisibleInView){
                    let posElId = this.state[key]['position_element_id']
                    console.log("DOOR ROW :: _optionsSelect :: this.state[key]['position_element_id'] => " + posElId )
                    console.log("DOOR ROW :: _optionsSelect :: [key]" + key)
                    console.log("DOOR ROW :: _optionsSelect :: width for the key is " + this.props.columnWidths[key])
                    return (
                        <OptionSelect
                            key={key}
                            onSelected={(selected, posElId) => this._onSelect(selected, posElId)}
                            columnWidth={this.props.columnWidths[key]}
                            options={this.state[key]['options']}
                            positionElementId={posElId}
                            selectedValue={this._processedSelectedValue(key)}
                        />
                    )
                } else {
                    return null;
                }
            })
        )
    }

    _selectedValue = () => {
        return 'ahahah';
    }

    _onSelect = (selected, positionElementId) => {
        console.log('DOOR ROW :: _onSelect  :: selected is: ' + JSON.stringify(selected))
        console.log('DOOR ROW :: _onSelect  :: positionElementId is: ' + JSON.stringify(positionElementId))
        let body = JSON.stringify({ selected: selected, position_element_id: positionElementId})
        //console.log('DOOR ROW :: _onSelect :: body => ' + body);
        fetch('http://localhost:3000/api/v1/option_selected',{
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: body,
        }).then((response) => {
            //console.log("RESPONSE OF FETCH: " + JSON.stringify(response))
            return response.json()
        })
            .then((positionElements) => {
                this._processPositionElementsChanges(positionElements)
            })
    }

    _processPositionElementsChanges(positionElements){
        //console.log("DOOR ROW :: _processPositionElementsChanges :: positionElements => " + JSON.stringify(positionElements))
        let newState = this.state
        //console.log("DOOR ROW :: _procesPositionElementsChanges :: oldState => " + JSON.stringify(newState));
        positionElements.map((hash)=> {
            //console.log("DOOR ROW :: _processPositionElementsChanges :: hash => " + JSON.stringify(hash))
            for(var key in hash){
                if(hash.hasOwnProperty(key)){
                    //console.log("KEY FOR NEW STATE: " + key)
                    //console.log("VALUE FOR NEW STATE: " + JSON.stringify(hash[key]))
                    newState[key] = hash[key]
                }
            }
            if(hash[key]['project_object_id'] != this.state.projectObjectId){
                //console.log("PROJECT OBJECT ID HAS CHANGED AND SHALL BE UPDATED!!!!!!!!!!!!!")
                newState['projectObjectId'] = hash[key]['project_object_id']
            }
            if(hash[key]['position_id'] != this.state.positionId){
                //console.log("CALCULATION ID HAS CHANGED AND SHALL BE UPDATED!!!!!!!!!!!!!")
                newState['positionId'] = hash[key]['position_id']
            }

        })
        //console.log("DOOR ROW :: _procesPositionElementsChanges :: newState => " + JSON.stringify(newState));
        this.setState(newState)
    }

    render () {
        console.log("DOOR ROW :: render ::  this.props.project_object_id =>  " + this.props.project_object_id);
        return (
                <DoorContainer>
                    <OptionsContainer>
                        <CheckBoxWrapper><CheckBoxInput className="check-for-order " data-project-object-id={this.state.projectObjectId} type="checkbox" defaultChecked={this.props.frameOrdered} data-type="order-select" onChange={this.handleChangeChk} data-project-id={this.props.project_id} data-door-configuration-id={this.props.doorConfigurationId} /></CheckBoxWrapper>
                        <CheckBoxWrapper><IWrapper className="fa fa-door-open" color={this.props.frame_ordered ? 'green' : 'lightcoral'} /></CheckBoxWrapper>
                        <CheckBoxWrapper><IWrapper className="fa fa-door-closed"  color={this.props.door_ordered ? 'green' : 'lightcoral'} /></CheckBoxWrapper>
                        <LinkContainer>
                            <a href={`/clone_configuration?door_configuration_id=${this.props.doorConfigurationId}`}><i className="fa fa-copy"></i></a>
                        </LinkContainer>
                        <LinkContainer>
                            <a href={`/delete_door_from_doorlist?door_configuration_id=${this.props.doorConfigurationId}`}><i className="fa fa-trash"></i></a>
                        </LinkContainer>
                    </OptionsContainer>
                    {this._optionsSelect()}
                </DoorContainer>
        );
    }
}


const IWrapper = styled.i`
    color: ${props => props.color};

`;

const CheckBoxInput = styled.input`
    
`;

const CheckBoxWrapper = styled.span`
    padding-top: 5px;
    padding-left: 4px;
`;

const LinkContainer = styled.div`
    padding: 5px;
`;

const RowNumber = styled.h3`
    padding: 5px;
`;

const OptionsContainer = styled.div`
    width: 120px;
    min-width: 100px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding-top: 3px;
`;

const DoorContainer = styled.div`
    display: inline-flex
    flex-direction: row;
    height: 50px;
`;

DoorRow.propTypes = {
    positionElements: PropTypes.object,
    index: PropTypes.number,
    columnWidths: PropTypes.array,
    positionDoorId: PropTypes.number
};
export default DoorRow
