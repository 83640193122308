const columnsDefinitions = (model) => {
    let defs = '';
    if(model == 'project'){
        defs = [
            {data: 'displayed_number'},
            { data: 'name' },
            { data: 'client' },
            { data: 'date' },
            { data: 'status'},
            { data: 'user'},
            { data: 'Actions', responsivePriority: -1}
        ]
    } else if (model == 'client'){
        defs = [ {data: 'name'}, {data: 'plz'}, {data: 'ort'},{data: 'Actions', responsivePriority: -1}]
    } else if (model == 'template') {
        defs = [ {data: 'name'}, {data: 'category'}, {data: 'Generate'},  {data: 'Actions', responsivePriority: -1}]
    } else if (model == 'library'){
        defs = [ {data: 'id'}, {data: 'name'}, {data: 'Actions', responsivePriority: -1}]
    }
    console.log("returning defs for columnsDefinitions: " + defs)
    return defs;
}

const resolveFields = model => {
    let fields = [];
    if (model == 'project'){
        fields = [ 'number', 'name', 'client', 'date', 'status', 'user', 'Actions']
    } else if (model == 'element' ) {
        fields = ['ID', 'Name', 'Category', 'Price', 'Actions']
    } else if (model == 'client'){
        fields = [ 'name', 'plz', 'ort', 'Actions']
    } else if (model == 'template'){
        fields = [ 'name', 'category', 'Generate',  'Actions']
    } else if (model == 'library'){
        fields = ['id', 'name', 'Actions']
    } else if (model == 'doorlist_template'){
        fields = ['name']
    }
    return fields;
}

const datatableUrl = () =>{
    let url = 'https://doors.spektre.ch/datatable'
    //let url = 'http://192.168.0.12:3000/datatable'
    return url
}
const categorySelectList = (column) => {
    let input = $(`<select class="form-control form-control-sm form-filter datatable-input" title="Select" data-col-index="` + column.index() + `">
										<option value="">Select</option></select>`);
    categories.map(function(category) {
        $(input).append('<option value="' + category[0] + '">' + category[1] + '</option>');
    })  

    return input;
}

const citySelectList = (column) => {
    let input = $(`<select class="form-control form-control-sm form-filter datatable-input" title="Select" data-col-index="` + column.index() + `">
										<option value="">Select</option></select>`);
    cities.map(function(city) {
        $(input).append('<option value="' + city[0] + '">' + city[1] + '</option>');
    })

    return input;
}

const usersSelectList = (column) => {
    let input = $(`<select class="form-control form-control-sm form-filter datatable-input" title="Select" data-col-index="` + column.index() + `">
										<option value="">Select</option></select>`);
    users.map(function(user) {
        $(input).append('<option value="' + user[0] + '">' + user[1] + '</option>');
    })

    return input;
}

const statusSelectList = (column) => {
    let input = $(`<select class="form-control form-control-sm form-filter datatable-input" title="Select" data-col-index="` + column.index() + `">
										<option value="">Select</option></select>`);
    statuses.map(function(status) {
        $(input).append('<option value="' + status[0] + '">' + status[1] + '</option>');
    })

    return input;
}

const yearsSelectList = (column) => {
    let input = $(`<select class="form-control form-control-sm form-filter datatable-input" title="Select" data-col-index="` + column.index() + `">
										<option value="">Select</option></select>`);
    years.map(function(year) {
        $(input).append('<option value="' + year[0] + '">' + year[1] + '</option>');
    })

    return input;
}

const years = [[2021, '2021'], [2022, '2022']];

const statuses = [[0, 'Offerte'], [1,'Projekt'], [2,'Vollendet']];

const categories = [[0, 'Zimmertürvorlage' ], [1, 'Brandschutztürvorlage'], [2, 'Whgs Eingangsvorlage' ], [3, 'Vorlage Tore'], [4, 'Zusatzpos Tore']];

const cities = [[1, "Peja"], [2, "Prishtina"], [3, "Prizreni"], [4, "Mitrovica"], [5, "Gjakova"], [6, "Ferizaj"], [7, "Fushe Kosove"], [8, "Gjilan"], [9, "Drenas"], [10, "Rahoves"], [11, "Podujeve"], [12, "Skenderaj"], [13, "Klina"], [14, "Malisheva"], [15, "Lypjan"], [16, "Vushtri"], [17, "Suhareke"], [18, "Viti"], [19, "Kamenice"], [20, "Dragash"], [21, "Kacanik"], [22, "Shtime"], [23, "Istog"], [24, "Decan"], [25, "Novoberde"], [26, "Shtrpce"], [27, "Obiliq"], [28, "Leposaviq"], [29, "Zubin Potok"], [30, "Mitrovica e veriut"], [31, "Hani i elezit"], [32, "Junik"], [33, "Graqanica"], [34, "Kllokot"], [35, "Ranillug"], [36, "Zvecan"], [37, "Partesh"], [99, "Pa Qytet"]];

const users =  [[4, "Sebastian Hug"], [5, "Sam Szameit"]]

export { columnsDefinitions, resolveFields, datatableUrl, citySelectList, statusSelectList, yearsSelectList, categorySelectList, usersSelectList }