
const OrderElements = () => {

    $(document).on('change', 'input:checkbox', function(){
        let type = $(this).attr('data-type')
        if(type == 'order-select'){
            let configurationRowId = $(this).attr('data-door-configuration-id');
            appendIdToDoorConfigurationIds(configurationRowId);
        }
    })

    $(document).on('click', '#new-order', function(){
        $.get()
    })

}

function appendIdToDoorConfigurationIds(configurationRowId) {
    let configurationRowIdsArray = $('#new-order').attr('data-door-configuration-ids')
    console.log("CONFIGURATION ROW IDS ARRAY " + configurationRowIdsArray)
    configurationRowIdsArray += configurationRowId
    configurationRowIdsArray += ','
    console.log('CONFIGURATION ROW IDS ARRAY AFTER ADDING CONFIGURATION ROW ID ' + configurationRowIdsArray)
    $('#new-order').attr('data-door-configuration-ids', configurationRowIdsArray)
}

export default OrderElements();