"use_strict";

export default function select2Initialization(selections) {
    console.log('SELECT2_INITIALIZATION :: selections => ' + selections)
    let stringified = JSON.parse(selections);

    $('.select2').each(function(){
        let $this = $(this);
        let positionId = $this.attr('data-position-element-id');
        let positionElementId = $(this).attr('id');
        for (const [key, value] of Object.entries(stringified)) {
            if(key == positionId){
                let elementToSelectify = $(`#${positionElementId}`)
                elementToSelectify.val(value);
                elementToSelectify.trigger('change');
            }
        }
    })
}

