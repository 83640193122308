"use_strict";

export default function calculate() {
    console.log('WILL DO CALCULATION ...');
    $('.category-calculation-row').each(function(){
        let brutPrice = parseFloat($(this).find('.brut-price').val());
        if(brutPrice && brutPrice > 0){
            console.log("CALCULATION :: position-category-item-row :: brutPrice is => " + brutPrice);
            let rabat1 = parseFloat($(this).find('.rabat1').val());
            let rabat2 = parseFloat($(this).find('.rabat2').val());
            let netPrice = calculateNetPrice(brutPrice, rabat1, rabat2);
            $(this).find('.net-price').val(netPrice.toFixed(2));
            recalculate();
            grandTotal();
        }
    })

    $('body').on('change', '#offer-for-piece-total', function(){
        let offerForPiece = $(this).val();
        console.log("OFFER FOR PIECE " + offerForPiece)
        let costPerPiece = $('#cost-per-piece').val();
        console.log("COST PER PIECE " + costPerPiece)
        let difference = offerForPiece - costPerPiece
        console.log("DIFFERENCE IS " + difference )
        let percentage = (difference / costPerPiece) * 100
        console.log("PERCENTAGE IS  " + percentage)
        let profit =  (costPerPiece / 100 ) * percentage
        $('#piece-profit-value').val(profit.toFixed(2))
        $('#piece-profit').val(percentage);
    })
}


function grandTotal(){
    let costPerPiece = parseFloat($('#cost-per-piece').val());
    let pieceProfit = $('#piece-profit').val();
    let pieceProfitValue = ((costPerPiece / 100) * pieceProfit);
    $('#piece-profit-value').val(pieceProfitValue.toFixed(2));
    let offerPerPiece = costPerPiece + pieceProfitValue;
    $('#offer-for-piece-total').val(offerPerPiece.toFixed(2));
    console.log("CALCULATE.js :: grandTotal :: offerPerPiece is => " + offerPerPiece);
    let pieces = $('#pieces').val();
    let positionTotal = (offerPerPiece * pieces).toFixed(2);
    $('#calculation-total').val(positionTotal)
    let calculationTotalProfit = pieceProfitValue * pieces
    $('#calculation-total-profit').val(calculationTotalProfit);
}

function recalculate(){
    let totals = totalsHash();
    $('.net-price').each(function(){
        let value = parseFloat($(this).val());
        let categoryId = $(this).attr('data-category-id');
        totals[categoryId] += value;
    })
    fillCategoryTotals(totals);
    let grandTotal = 0;
    for(const [key, value] of Object.entries(totals)){
        grandTotal += value
    }
    $('#cost-per-piece').val(grandTotal);
}


function fillCategoryTotals(totals){
    $('.category-total').each(function(){
        let categoryId = $(this).attr('data-category-id')
        //console.log('CATEGORY TOTAL FOR: ' + categoryId);
        //console.log('TOTAL FOR THE CATEGORY: ' + totals[categoryId]);
        $(this).text(totals[categoryId].toFixed(2));
    })
}

function calculateNetPrice(brutPrice, rabat1, rabat2){
    let netPrice = 0.0;
    netPrice = brutPrice - ((brutPrice / 100) * rabat1) - ((brutPrice - ((brutPrice / 100) * rabat1)) / 100) * rabat2;
    return netPrice;
}

function totalsHash() {
    let hash = {}
    hash[0] = 0.0;
    hash[1] = 0.0;
    hash[2] = 0.0;
    hash[3] = 0.0;
    hash[4] = 0.0;
    hash[5] = 0.0;
    hash[6] = 0.0;
    return hash;
}

