import React from 'react';
import styled from 'styled-components';
import Select from 'react-select'
import PropTypes from 'prop-types';
import CreatableSelect from 'react-select/creatable';




function OptionSelect ({ positionDoorId, options, columnWidth, onSelected, positionElementId, selectedValue }) {
    const _onChangeSelect = (selected, posElId) => {
        console.log("OPTION SELECT :: _onChangeSElect :: positionDoorId => " + positionDoorId)
        console.log("OPTION SELECT :: _onChangeSElect :: options => " + options)
        console.log("OPTION SELECT :: _onChangeSElect :: columnWidth => " + columnWidth)
        console.log('OPTION SELECT :: _onChangeSelect :: selected...' + JSON.stringify(selected))
        console.log("OPTION SELECT :: _onChangeSElect :: positionElementId => " + JSON.stringify(positionElementId));
        //console.log('OPTION SELECT :: _onChangeSelect  ' + onSelected)
        let kaki = JSON.stringify(positionElementId)
        onSelected(selected['value'], kaki);
    }

    return (

        <Container width={columnWidth}>
            <CreatableSelect
                options={options}
                value={selectedValue}
                onChange={(selected, positionElementId)=> _onChangeSelect(selected, positionElementId)}/>
        </Container>
    )
}



const Container = styled.div`
    width: ${(props)=> props.width}px;
`;

 OptionSelect.propTypes = {
    positionDoorId: PropTypes.number,
     positionElementId: PropTypes.number,
    options: PropTypes.array,
    columnWidth: PropTypes.number,
    onSelected: PropTypes.func,
     selectedValue: PropTypes.object,

 }

export default OptionSelect