// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("trix")
require("@rails/actiontext")
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require('datatables.net-bs4')
require('datatables.net-responsive-bs4')
require('../packs/datatables.responsive.js')
require('../packs/main');
require('../packs/scripts.bundle');
require('bootstrap-datepicker');
require('select2');
require('datatables.net-bs4');
require('datatables.net-responsive-bs4');
//require('../packs/datatables.bundle');
require("datatables.net-autofill")
require("../packs/column-search")

import jQuery from  'jquery'

global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery;

import "bootstrap";

import "@fortawesome/fontawesome-free/js/all";
import "../controllers/composer_controller";
import { createTree } from 'jquery.fancytree';

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import "controllers"

import "../scss/main"

import "select2"
import "select2/dist/css/select2.css";
import "../scss/print.scss";
import select2Initialization from  "./selec2_initialization";
import calculate from './calculate';
import calculateOffer from "./CalculateOffer";
import 'jquery.fancytree/dist/skin-awesome/ui.fancytree.css';
import 'jquery.fancytree/dist/modules/jquery.fancytree.edit';
import 'jquery.fancytree/dist/modules/jquery.fancytree.filter';
import './OrderElements';

$(document).on('turbolinks:load', function() {
    console.log('we are in')



    $('.subelements-loader').on('click', function(){
        console.log('I will try to call stimulus from here...')
    })

    $(document).on('change', '.select-all-object-doors', function(){
        let projectObjectId = parseInt($(this).attr('data-project-object-id'));
        console.log('OUTTER PROJECT OBJECT ID IS ' + projectObjectId);
        let status = $(this).is(':checked');
        console.log('status of changed box is ' + status);
        $('.check-for-order').each(function(i, input){
            let innerProjectObjectId = parseInt($(this).attr('data-project-object-id'));
            console.log("INNER PROJECT OBJECT ID IS " + innerProjectObjectId)
            console.log(innerProjectObjectId == projectObjectId)
            if(innerProjectObjectId == projectObjectId){
                console.log('SHOULD CHANGE STATUS TO : ' + status)
                if(status){
                    $(this).attr('checked', true)
                } else {
                    $(this).attr('checked', false)
                }
            }
        })
    })

    $('.select2tagged').select2({
        tags: true
    })

    $('body').on('change', '#trigger_option_change_options_type', function(){
        console.log('will show this option : ' + $(this).val());
        let typeToShow = $(this).val();
        $('.option-type-ot').each( function(){
            let div = $(this);
            let elementType = div.attr('data-type')
            console.log('elementType is: ' + elementType)
            if(elementType == typeToShow){
                div.css('display', 'block')
            } else {
                div.css('display', 'none')
            }
        })
    })

    $('body').on('change', '#formula_type_id', function(){
        let formulaTypeIndex = $(this).prop('selectedIndex');
        console.log("selected formula type index: " + formulaTypeIndex);
        if(formulaTypeIndex == 1){
            $('#source-element1').css('display', 'block');
            $('#source-element2').css('display', 'block');
        } else if (formulaTypeIndex == 2){
            $('#source-element1').css('display', 'block');
        } else if (formulaTypeIndex == 3){
            $('#source-element1').css('display', 'block');
            $('#source-element2').css('display', 'block');
        } else if (formulaTypeIndex == 4){
            $('#source-element1').css('display', 'block');
        } else if (formulaTypeIndex == 5) {
            $('#source-element1').css('display', 'block');
        } else if (formulaTypeIndex == 6) {
            $('#source-element1').css('display', 'block');
        } else if (formulaTypeIndex == 7) {
            $('#source-element1').css('display', 'block');
        }
    })

    $('body').on('change', '.formula-option-result-value', function(){
        let formulaOptionId = $(this).attr('data-formula-option-id');
        let result = $(this).val();
        let idToUpdate = `#formula-option-${formulaOptionId}-update`
        $(idToUpdate).attr('data-result', result);
    })


    $('.select2').each(function() {
        let selected = $(this).attr('data-selected');
        let positionItemId = $(this).attr('data-position-item-id');
        let $this = $(this);
        console.log('position item id is: ' + positionItemId);
        console.log("selected is: " +  selected);
        if(selected){
            console.log('will set selected for: ' + positionItemId + ' to ' + selected);
            let s2 =  $this.select2({
                tags: true,
            })
            s2.val(selected).trigger('change');
        } else {
            console.log("WILL GO FOR SECOND OPTION FOR SELECT ...")
            let s1= $('.select2').select2({
                allowClear: true,
                placeholder: '',
                tags: true,
                multiple: true
            })
            //s1.val([' ']).trigger('change');

        }
    })


    $('body').on('select2:select', '.select2', function(){
        console.log("THIS IS SELECT 2 SELECT ...")
        let selectedOptionId = $(this).val();
        console.log('select2 option changed and value is: ' + selectedOptionId)
        let select2type = $(this).attr('data-select2-type');
        console.log('select2 type is: ' + select2type);
        if(select2type == 'calculation-row-name'){
            $(this).attr('data-calculation-row-name', selectedOptionId)
        } else {
            $(this).attr('data-selected-element-id', selectedOptionId)
        }
        let event = new Event('change', { bubbles: true })
        this.dispatchEvent(event);
    })

    let dataElementsSelectionsDiv = $('#position-elements-selections');
    console.log('dataElementsSelectionsDiv is: ' + dataElementsSelectionsDiv,)
    if(Object.keys(dataElementsSelectionsDiv).length > 0 ){
        let selectedPositionElementValues = dataElementsSelectionsDiv.attr('data-elements-selections')
        select2Initialization(selectedPositionElementValues);
    }

    $('body').on('change', '.brut-price', function(){
        calculate();
    })

    $('body').on('change', '.rabat1', function(){
        calculate();
    })



    $(document).on('click', '#toggle-import-door-configuration', function(){
        console.log('should toggle import door configuration body...')
        let displayed = $(this).attr('data-displayed')
        if(displayed == 'false'){
            $(this).attr('data-displayed', true)
            $('#import-door-configuration-body').css('display', 'block')
        } else {
            $(this).attr('data-displayed', false)
            $('#import-door-configuration-body').css('display', 'none')
        }
    })

    $(document).on('change', '#choose-object-to-import-doorlist-template', function(){
        let choosenObjectId = $(this).val();
        console.log('choosen objet is' + choosenObjectId);
        $('.import-template-button').each(function(){
            console.log('in button ')
            let link = $(this).attr('href')
            console.log('href in button is ' + link)
            let newLink = `${link}&object_id=${choosenObjectId}`
            $(this).attr('href', newLink);
        })
    })

    $('body').on('change', '.rabat2', function(){
        calculate();
    })


    $('body').on('change', '#piece-profit', function(){
        calculate();
    })

    $('body').on('change', '#pieces', function(){
        calculate();
    })

    let editPositionDiv = $('#edit-position');
    if(editPositionDiv){
        calculate();
    }

    let calculationCardHeight = $('#calculation-card').height();

    if(calculationCardHeight) {
        console.log('card height is: '+ calculationCardHeight);
        $('#calculation-templates-card').css('height', calculationCardHeight)
    }

    calculateOffer();

    $(document).on('change','.offer-discount-field', function(){
        calculateOffer()
    })

    $('.calculation-checkbox').on('change', function(){
        let type = $(this).attr('data-type');
        let selected = $(this).is(":checked")
        //console.log("TYPE OF CHECKED CHECKBOX IS: " + type + ' selected is ' + selected);
        let $this = $(this);
        if(type == 'object'){
            let objectId = $this.attr('data-project-object-id');
            //console.log("YOU CLICKED ON CHECKBOX THAT IS PROJECT OBJEC WITH ID: " + objectId)
            $('.calculation-checkbox').each(function() {
                let $$this = $(this)
                let parentObjectId = $(this).attr('data-parent-object-id');
                //console.log("PARENT OBJECT ID IS: " + parentObjectId)
                if(parentObjectId == objectId){
                    //console.log('I WILL SELECT THIS ONE SINCE ITS PARENT WAS CHECKED');
                    if(selected){
                        $$this.attr("checked", true)
                    } else {
                        $$this.attr("checked", false)
                    }
                }
            })
        } else {
            if($this.is(":checked")){
                console.log('checkbox status is checked ')
            } else {
                console.log('checkbox status is UNchecked ')
                let row = $this.closest('.offer-item-row')
                row.find('.row-total').text(0)
                row.find('.row-profit').text(0)
            }
        }
        calculateOffer()
    })

    $('.offered-price').on('change', function(){
        calculateOffer();
    })

    $('body').on('change', '#include-project-manager-hours', function() {
        console.log('changed include project manager hours...')
        calculateOffer();
    })

    $('.offered-quantity').on('change', function(){
        calculateOffer();
    })
    $('.offer-percentage-field').on('change', function(){
        calculateOffer();
    })

    $('#variation_gross_price').on('change', function(){
        calculateVariationOfferPrice()
    })
    $('#variation_rabat1').on('change', function(){
        calculateVariationOfferPrice()
    })
    $('#variation_rabat2').on('change', function(){
        calculateVariationOfferPrice()
    })
    $('#variation_profit_margin').on('change', function(){
        calculateVariationOfferPrice()
    })

    $('#project_at_client_address').on('change', function(){
        if($(this).is(':checked')){
            $('#project-address-div').css('display', 'none')
        } else {
            $('#project-address-div').css('display', 'block')
        }
    })

    $('#libraries-search-term').on('change', function(){
        let term = $(this).val();
        $('#search-libraries-button').attr('data-search-term', term)
    })

    $('body').on('change', '.formula-option-source-value1', function(){
        let value = $(this).val();
        let row = $(this).closest('.formula-option-row')
        row.find('.formula-option-save-button').attr('data-formula-source-value1', value);
    })

    $('body').on('change', '.formula-option-source-value2', function(){
        let value = $(this).val();
        let row = $(this).closest('.formula-option-row')
        row.find('.formula-option-save-button').attr('data-formula-source-value2', value);
    })

    //$('.remove-row').on('click', function(){
    //    console.log('should remove some row')
    //    $(this).closest('.position-category-item-row').html('<div></div>')
    //})

});// Support component names relative to this directory:
var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);

function calculateVariationOfferPrice(){
    let offerPrice = 0;
    let grossPrice = $('#variation_gross_price').val();
    let rabat1 = $('#variation_rabat1').val();
    let rabat2 = $('#variation_rabat2').val();
    let netPrice = grossPrice
    if(rabat1){
        netPrice = netPrice - ((grossPrice / 100) * rabat1)
    }
    if(rabat2){
        netPrice = netPrice - ((grossPrice / 100) * rabat2)
    }
    $('#variation_net_price').val(netPrice);
    let margin = $('#variation_profit_margin').val();
    if(margin){
        offerPrice = netPrice + ((netPrice / 100) * margin)
    } else {
        offerPrice = netPrice
    }
    $('#variation_offer_for_piece').val(offerPrice);

}
require("trix")
require("@rails/actiontext")