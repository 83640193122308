import ApplicationController from './application_controller'
import select2Initialization from "../packs/selec2_initialization";

/* This is the custom StimulusReflex controller for the Example Reflex.
 * Learn more at: https://docs.stimulusreflex.com
 */
export default class extends ApplicationController {
    /*
     * Regular Stimulus lifecycle methods
     * Learn more at: https://stimulusjs.org/reference/lifecycle-callbacks
     *
     * If you intend to use this controller as a regular stimulus controller as well,
     * make sure any Stimulus lifecycle methods overridden in ApplicationController call super.
     *
     * Important:
     * By default, StimulusReflex overrides the -connect- method so make sure you
     * call super if you intend to do anything else when this controller connects.
    */

    connect () {
        super.connect();
        console.log('Connected stimulus calculation templates  controller...')
    }


    /* Reflex specific lifecycle methods.
     *
     * For every method defined in your Reflex class, a matching set of lifecycle methods become available
     * in this javascript controller. These are optional, so feel free to delete these stubs if you don't
     * need them.
     *
     * Important:
     * Make sure to add data-controller="example" to your markup alongside
     * data-reflex="Example#dance" for the lifecycle methods to fire properly.
     *
     * Example:
     *
     *   <a href="#" data-reflex="click->Example#dance" data-controller="example">Dance!</a>
     *
     * Arguments:
     *
     *   element - the element that triggered the reflex
     *             may be different than the Stimulus controller's this.element
     *
     *   reflex - the name of the reflex e.g. "Example#dance"
     *
     *   error/noop - the error message (for reflexError), otherwise null
     *
     *   reflexId - a UUID4 or developer-provided unique identifier for each Reflex
     */

    // Assuming you create a "Example#dance" action in your Reflex class
    // you'll be able to use the following lifecycle methods:

    // beforeDance(element, reflex, noop, reflexId) {
    //  element.innerText = 'Putting dance shoes on...'
    // }

    // danceSuccess(element, reflex, noop, reflexId) {
    //   element.innerText = '\nDanced like no one was watching! Was someone watching?'
    // }

    // danceError(element, reflex, error, reflexId) {
    //   console.error('danceError', error);
    //   element.innerText = "\nCouldn\'t dance!"
    // }

    // afterDance(element, reflex, noop, reflexId) {
    //   element.innerText = '\nWhatever that was, it\'s over now.'
    // }

    // finalizeDance(element, reflex, noop, reflexId) {
    //   element.innerText = '\nNow, the cleanup can begin!'
    // }
    afterImport(element, reflex, noop, reflexId){
        $('.select2').each(function() {
            let selected = $(this).attr('data-selected');
            let positionItemId = $(this).attr('data-position-item-id');
            let $this = $(this);
            console.log('position item id is: ' + positionItemId);
            console.log("selected is: " +  selected);
            if(selected){
                console.log('will set selected for: ' + positionItemId + ' to ' + selected);
                let s2 =  $this.select2({
                    tags: true,
                })
                s2.val(selected).trigger('change');
            } else {
                let s1= $('.select2').select2({
                    allowClear: true,
                    placeholder: 'Please select an option',
                    tags: true,
                })
                s1.val([' ']).trigger('change');

            }
        })

    }


    afterItemSelected(element, reflex, noop, reflexId){
        console.log("REFLEX ID IN AFTER ROW IS: " + reflexId)
        let elementId=`#${reflexId}`;
        let selected = $(elementId).attr('data-selected');
        console.log("AFTER ITEM SELECTED :: selected => " + selected);
        let positionItemId = $(elementId).attr('data-position-item-id');

        console.log('position item id is: ' + positionItemId);
        console.log("selected is: " +  selected);
        console.log('will set selected for: ' + positionItemId + ' to ' + selected);
        let s3 = $(elementId).select2({
            tags: true,
        })
        s3.val(selected).trigger('change');
    }


}


