import {columnsDefinitions, resolveFields, citySelectList, agentSelectList, datatableUrl} from "../helpers/datatables_helpers";

const ClientDatatable = () => {

    //const model = $('#context').attr('data-model');
    //console.log('DT MODEL IS: ' + model);
    const tableFields = resolveFields(model);
    console.log("TABLE FIELDS ARE: " + tableFields)
    // begin first table
    let model = 'client';
    console.log("datatableurl is: " + datatableUrl())
    let table = $('#kt_datatable').DataTable({
        responsive: true,
        autoWidth: false,
        // Pagination settings
        dom: `<'row'<'col-sm-12'tr>>
         <'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7 dataTables_pager'lp>>`,
        // read more: https://datatables.net/examples/basic_init/dom.html
        lengthMenu: [5, 10, 25, 50],
        pageLength: 10,
        language: {
            'lengthMenu': 'Display _MENU_',
        },
        searchDelay: 500,
        processing: true,
        serverSide: true,
        ajax: {
            url: datatableUrl(),
            type: 'GET',
            data: {
                // parameters for custom backend script demo
                model: model,
                columnsDef: tableFields,
            },
        },
        columns: columnsDefinitions(model),
        initComplete: function() {
            var thisTable = this;
            var rowFilter = $('<tr class="filter"></tr>').appendTo($(table.table().header()));

            this.api().columns().every(function() {
                var column = this;
                var input;
                switch (column.title()) {
                    case 'Name':
                    case 'Plz':
                    case 'Ort':
                        input = $(`<input type="text" class="form-control form-control-sm form-filter datatable-input" data-col-index="` + column.index() + `"/>`);
                        break;
                    case 'Actions':
                        var search = $(`
                                <button class="btn btn-primary kt-btn btn-sm kt-btn--icon d-block" style="margin-top: 5px;">
                             <span>
                                 <i class="la la-search"></i>
                                 <span>Search</span>
                             </span>
                         </button>`);

                        var reset = $(`
                                <button class="btn btn-secondary kt-btn btn-sm kt-btn--icon" style="margin-top: -30px;">
                             <span>
                                <i class="la la-close"></i>
                                <span>Reset</span>
                             </span>
                         </button>`);

                        $('<th>').append(search).append(reset).appendTo(rowFilter);

                        $(search).on('click', function(e) {
                            e.preventDefault();
                            var params = {};
                            $(rowFilter).find('.datatable-input').each(function() {
                                var i = $(this).data('col-index');
                                if (params[i]) {
                                    params[i] += '|' + $(this).val();
                                } else {
                                    params[i] = $(this).val();
                                }
                            });
                            $.each(params, function(i, val) {
                                // apply search params to datatable
                                table.column(i).search(val ? val : '', false, false);
                            });
                            table.table().draw();
                        });

                        $(reset).on('click', function(e) {
                            e.preventDefault();
                            $(rowFilter).find('.datatable-input').each(function(i) {
                                $(this).val('');
                                table.column($(this).data('col-index')).search('', false, false);
                            });
                            table.table().draw();
                        });
                        break;
                }

                if (column.title() !== 'Actions') {
                    $(input).appendTo($('<th>').appendTo(rowFilter));
                }
            });

            // hide search column for responsive table
            var hideSearchColumnResponsive = function() {
                thisTable.api().columns().every(function() {
                    var column = this
                    if (column.responsiveHidden()) {
                        $(rowFilter).find('th').eq(column.index()).show();
                    } else {
                        $(rowFilter).find('th').eq(column.index()).hide();
                    }
                })
            };

            // init on datatable load
            hideSearchColumnResponsive();
            // recheck on window resize
            window.onresize = hideSearchColumnResponsive;

            $('#kt_datepicker_1,#kt_datepicker_2').datepicker();
        },
        columnDefs:  [{
            targets: -1,
            width: '50px',
            title: 'Actions',
            orderable: false,
            render: function(data, type, full, meta) {
                return '<span>' + data + '</span>';
            },
        },
            {
                targets:0,
                width: '150px',
            },
            {
                targets: 1,
                width: '200px'
            },
            {
                targets: 2,
                width: '200px'
            },
            {
                targets: 3,
                width: '200px'
            }
        ]
    });

    return table;
}








export default ClientDatatable;